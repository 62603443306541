<template>
  <DamList :is-pdf="true" />
</template>
<script>
import DamList from '@/components/dam/DamList'
export default {
  name: 'DamPdfList',
  components: { DamList }
}
</script>
